import { AxiosInstance, AxiosResponse } from 'axios';
import queryString from 'query-string';

import {
  BlueprintModification,
  CreateRequest,
  ExportQueryParams,
  ExportedData,
  ImportQueryParams,
  ImportedData,
  InsuranceFirm,
  InsuranceFirmWithProductCount,
  InsuranceProduct,
  InsuranceProductCreateRequest,
  InsuranceProductPricing,
  InsuranceProductPricingCreateRequest,
  InsuranceProductUpdateRequest,
  LeadAccessTokenData,
  PageQueryOptions,
  Permission,
  QuestionnaireVersionInfo,
  QuestionnaireVersionInfoPage,
  QuestionnaireVersionRowData,
  SalesDecisionRule,
  Settings,
  SettingsData,
  UpdateRequest,
  User,
  UserImportData,
  UserPage,
  UsersImportResponse,
  QuestionnairePreviewData,
  QuestionnairePreviewQuery,
  QuestionnaireBlueprint,
  QuestionnaireVersionDetail,
  ExistingLeadAndApplicationPreview,
  Paginated,
  ConsiderationBlueprintRowData,
  QuestionnaireBuilderAndPdfEntities,
  Language,
  LocalizedThirdPartyIntegration,
  SectionPartIdentifier,
  ImportCopyDeckResponse,
  UpdateSalesDecisionRuleData,
  CreateSalesDecisionRuleData,
  EntityMapping,
  GetEntityMappingParams,
  GetEntityMappingReturnValue,
  Entity,
  FindEntityQuery,
  CreateEntityData,
  UpdateEntityData,
  EntityMappingDependencies,
  FindUtilitiesTypeDeclarationsParameters,
  TestEntityMappingQueryParameters,
  Questionnaire,
  LineOfBusiness,
  CreateQuestionnaireData,
  QuestionnaireSchema,
  UserGroup,
} from '@breathelife/types';

import { buildPageQueryOptions, convertObjectToQueryString } from './helpers';
import Urls from './urls';

type ProductFilter = {
  productId: string;
};

export class AdminGateway {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    // NOTE: This object is created in the `Gateway` constructor, any modifications will also apply in that class. (if we want distinct interceptors some refactoring will be needed)
    this.instance = instance;
  }

  public async fetchSettings(): Promise<AxiosResponse<Settings>> {
    return this.instance.get(Urls.admin.settings);
  }

  public async updateSettings(
    settingsId: string,
    updatedSettings: { data: SettingsData },
  ): Promise<AxiosResponse<Settings>> {
    return this.instance.patch(`${Urls.admin.settings}/${settingsId}`, updatedSettings);
  }

  public async getLatestQuestionnaire(): Promise<AxiosResponse<Questionnaire>> {
    return this.instance.get<Questionnaire>(Urls.admin.latestQuestionnaire);
  }

  public async fetchQuestionnaireVersions(
    questionnaireId: string,
    options?: PageQueryOptions<QuestionnaireVersionRowData>,
  ): Promise<AxiosResponse<QuestionnaireVersionInfoPage>> {
    const queryOptions = options ? buildPageQueryOptions(options) : {};
    const queryOptionsWithQuestionnaireId = { ...queryOptions, questionnaireId };
    const queryString = convertObjectToQueryString<QuestionnaireVersionRowData>(queryOptionsWithQuestionnaireId);
    return await this.instance.get<QuestionnaireVersionInfoPage>(`${Urls.admin.questionnaireVersions}/${queryString}`);
  }

  public async fetchAllQuestionnaireVersions(questionnaireId: string): Promise<QuestionnaireVersionInfo[]> {
    const queryString = convertObjectToQueryString<QuestionnaireVersionRowData>({ fetchAll: true, questionnaireId });
    const response = await this.instance.get<QuestionnaireVersionInfoPage>(
      `${Urls.admin.questionnaireVersions}/${queryString}`,
    );

    return response.data.data;
  }

  public async fetchQuestionnaireVersion(
    questionnaireVersionId: string,
  ): Promise<AxiosResponse<QuestionnaireVersionDetail>> {
    return this.instance.get(`${Urls.admin.questionnaireVersions}/${questionnaireVersionId}`);
  }

  public async getQuestionnaireSchema(questionnaireVersionId: string): Promise<AxiosResponse<QuestionnaireSchema>> {
    return this.instance.get(`${Urls.admin.questionnaireSchemas}/${questionnaireVersionId}`);
  }

  public async patchQuestionnaireVersion(
    questionnaireVersionId: string,
    payload: BlueprintModification,
  ): Promise<
    AxiosResponse<{
      id: string;
      blueprint: QuestionnaireBlueprint;
      questionnaireId: string;
      isDraft: boolean;
      majorVersion: number;
      minorVersion: number;
    }>
  > {
    return this.instance.patch(`${Urls.admin.questionnaireVersions}/${questionnaireVersionId}`, payload);
  }

  public async publishQuestionnaireVersion(questionnaireVersionId: string, description: string): Promise<void> {
    return this.instance.post(`${Urls.admin.publishQuestionnaireVersion}`, { questionnaireVersionId, description });
  }

  public async newDraftQuestionnaireVersion(data: {
    questionnaireId: string;
    description?: string;
  }): Promise<AxiosResponse<string>> {
    return this.instance.post(`${Urls.admin.newDraftQuestionnaireVersion}`, data);
  }

  public async fetchSalesDecisionRules(
    options?: PageQueryOptions<ConsiderationBlueprintRowData> & { questionnaireVersionId?: string },
  ): Promise<AxiosResponse<Paginated<SalesDecisionRule>>> {
    const queryString = options ? convertObjectToQueryString(options) : '';
    const url = `${Urls.admin.salesDecisionRules}/${queryString}`;
    return this.instance.get(url);
  }

  public async createSalesDecisionRule(data: CreateSalesDecisionRuleData): Promise<AxiosResponse<SalesDecisionRule>> {
    return this.instance.post(Urls.admin.salesDecisionRules, data);
  }

  public async createQuestionnairePreviewLink(
    data: QuestionnairePreviewData,
  ): Promise<AxiosResponse<LeadAccessTokenData>> {
    return this.instance.post(Urls.admin.questionnairePreview, data);
  }

  public async getQuestionnairePreviewApplicationData(
    query: QuestionnairePreviewQuery,
  ): Promise<AxiosResponse<ExistingLeadAndApplicationPreview>> {
    return this.instance.get(queryString.stringifyUrl({ url: Urls.admin.questionnairePreview, query }));
  }

  public async updateSalesDecisionRule(
    ruleId: string,
    data: UpdateSalesDecisionRuleData,
  ): Promise<AxiosResponse<SalesDecisionRule>> {
    return this.instance.put(`${Urls.admin.salesDecisionRules}/${ruleId}`, data);
  }

  public async deleteSalesDecisionRule(ruleId: string): Promise<AxiosResponse<{ success?: boolean }>> {
    return this.instance.delete(`${Urls.admin.salesDecisionRules}/${ruleId}`);
  }

  public async createUser(data: unknown): Promise<AxiosResponse<User>> {
    return this.instance.post<User>(`${Urls.admin.users}`, data);
  }

  public async bulkCreateUsers(users: UserImportData[]): Promise<AxiosResponse<UsersImportResponse>> {
    return this.instance.post<UsersImportResponse>(`${Urls.admin.bulkUsers}`, { users });
  }

  public async updateUser(id: string, data: Partial<User>): Promise<AxiosResponse<User>> {
    return this.instance.patch<User>(`${Urls.admin.users}/${id}`, data);
  }

  public async getUser(auth0Id: string): Promise<AxiosResponse<User>> {
    return this.instance.get<User>(`${Urls.admin.users}/${auth0Id}`);
  }

  public async getUsers(queryString: string): Promise<AxiosResponse<UserPage>> {
    return this.instance.get<UserPage>(`${Urls.admin.users}${queryString}`);
  }

  public async syncUser(auth0Id: string, lang?: string): Promise<AxiosResponse<User>> {
    return this.instance.post<User>(`${Urls.admin.usersSync}`, { auth0Id, lang });
  }

  public async fetchUserPermissions(): Promise<AxiosResponse<Permission[]>> {
    return this.instance.get<Permission[]>(`${Urls.admin.userPermissions}`);
  }

  public async sendAuth0InvitationEmail(userId: string): Promise<AxiosResponse<void>> {
    return this.instance.post(`${Urls.admin.userInvitations}`, { id: userId });
  }

  public async transferUser(fromId: string, toId: string): Promise<AxiosResponse<void>> {
    return this.instance.post(`${Urls.admin.userTransfers}`, { from: fromId, to: toId });
  }

  public async deleteUser(userId: string): Promise<AxiosResponse<User>> {
    return this.instance.delete(`${Urls.admin.users}/${userId}`);
  }

  public async fetchProducts(query?: ProductFilter): Promise<AxiosResponse<InsuranceProduct[]>> {
    let queryString = '';
    if (query) {
      queryString = convertObjectToQueryString<ProductFilter>(query);
    }
    return this.instance.get(`${Urls.admin.insuranceProducts}${queryString}`);
  }

  public async fetchFirms(): Promise<AxiosResponse<InsuranceFirmWithProductCount[]>> {
    return this.instance.get(Urls.admin.insuranceFirms);
  }

  public async createProduct(data: InsuranceProductCreateRequest): Promise<AxiosResponse<InsuranceProduct>> {
    return this.instance.post(Urls.admin.insuranceProducts, data);
  }

  public async createFirm(data: CreateRequest<InsuranceFirm>): Promise<AxiosResponse<InsuranceFirm>> {
    return this.instance.post(Urls.admin.insuranceFirms, data);
  }

  public async fetchProduct(productId: string): Promise<AxiosResponse<InsuranceProduct>> {
    return this.instance.get(`${Urls.admin.insuranceProducts}/${productId}`);
  }

  public async fetchFirm(firmId: string): Promise<AxiosResponse<InsuranceFirm>> {
    return this.instance.get(`${Urls.admin.insuranceFirms}/${firmId}`);
  }

  public async updateFirm(firmId: string, data: UpdateRequest<InsuranceFirm>): Promise<AxiosResponse<InsuranceFirm>> {
    return this.instance.put(`${Urls.admin.insuranceFirms}/${firmId}`, data);
  }

  public async updateProduct(
    productId: string,
    data: InsuranceProductUpdateRequest,
  ): Promise<AxiosResponse<InsuranceProduct>> {
    return this.instance.put(`${Urls.admin.insuranceProducts}/${productId}`, data);
  }

  public async createProductPricing(
    data: InsuranceProductPricingCreateRequest,
  ): Promise<AxiosResponse<InsuranceProductPricing>> {
    const formData = new FormData();
    formData.append('insuranceProductId', data.insuranceProductId);

    if (data.rateSheetFile) {
      formData.append('file', data.rateSheetFile);
    }

    if (data.insuranceProductPricingId) {
      formData.append('insuranceProductPricingId', data.insuranceProductPricingId);
    }

    if (data.modalFactor) {
      formData.append('modalFactor', JSON.stringify(data.modalFactor));
    }

    if (typeof data.policyFee !== 'undefined') {
      formData.append('policyFee', data.policyFee.toString());
    }

    return this.instance.post(Urls.admin.insuranceProductPricing, formData);
  }

  public async fetchApplicationSchemaForQuestionnaire(
    questionnaireVersionId: string,
  ): Promise<AxiosResponse<Record<string, unknown>>> {
    return this.instance.get(`${Urls.admin.applicationSchemas}/${questionnaireVersionId}`);
  }

  public async fetchDataForExport(query: ExportQueryParams): Promise<AxiosResponse<ExportedData>> {
    return this.instance.get(
      queryString.stringifyUrl({
        url: Urls.admin.exportData,
        query,
      }),
    );
  }

  public async uploadDataForImport(query: ImportQueryParams, data: ImportedData): Promise<AxiosResponse<ImportedData>> {
    const url = queryString.stringifyUrl({
      url: Urls.admin.importData,
      query,
    });

    return this.instance.put(url, data);
  }

  public async uploadPdfForQuestionnaireBuilder(
    pdfDataUri: string,
    fileName: string,
    thresholdSpaceToCharacterWidthRatio?: number,
  ): Promise<AxiosResponse<QuestionnaireBuilderAndPdfEntities>> {
    return this.instance.post(Urls.admin.questionnaireBuilder, {
      pdfDataUri,
      fileName,
      thresholdSpaceToCharacterWidthRatio,
    });
  }
  public async getLatestQuestionnaireBuilder(): Promise<AxiosResponse<QuestionnaireBuilderAndPdfEntities>> {
    return this.instance.get(`${Urls.admin.questionnaireBuilder}`);
  }

  public async fetchThirdPartyIntegrations(
    language: Language,
  ): Promise<AxiosResponse<LocalizedThirdPartyIntegration[]>> {
    return this.instance.get(`${Urls.admin.thirdPartyIntegrations}?language=${language}`);
  }

  public async fetchLatestThirdPartyIntegrationConfiguration(
    thirdParyIntegrationId: string,
  ): Promise<AxiosResponse<LocalizedThirdPartyIntegration[]>> {
    return this.instance.get(`${Urls.admin.thirdPartyIntegrations}/${thirdParyIntegrationId}`);
  }

  public async uploadCopyDeck(
    query: { questionnaireVersionId: string },
    data: { copyDeckCSV: string; parentPartIdentifier: SectionPartIdentifier },
  ): Promise<AxiosResponse<ImportCopyDeckResponse>> {
    const url = queryString.stringifyUrl({
      url: Urls.admin.importCopyDeck,
      query,
    });

    return this.instance.post(url, data);
  }

  public async retrieveEntityMapping(
    query: GetEntityMappingParams,
  ): Promise<AxiosResponse<GetEntityMappingReturnValue>> {
    const url = queryString.stringifyUrl({
      url: Urls.admin.entityMappings,
      query,
    });

    return this.instance.get(url);
  }

  public async getEntityMappingDependencies(entityMappingId: string): Promise<AxiosResponse<Entity[]>> {
    const url = queryString.stringifyUrl({
      url: Urls.admin.entityMappingDependencies,
      query: { entityMappingId },
    });

    return this.instance.get(url);
  }

  public async addEntityMappingDependency(
    data: EntityMappingDependencies,
  ): Promise<AxiosResponse<EntityMappingDependencies>> {
    return this.instance.post(Urls.admin.entityMappingDependencies, data);
  }

  public async removeEntityMappingDependency(
    data: EntityMappingDependencies,
  ): Promise<AxiosResponse<EntityMappingDependencies>> {
    const url = queryString.stringifyUrl({
      url: Urls.admin.entityMappingDependencies,
      query: data,
    });

    return this.instance.delete(url);
  }

  public async saveEntityMapping(data: EntityMapping): Promise<AxiosResponse<EntityMapping>> {
    return this.instance.put(Urls.admin.entityMappings, data);
  }

  public async getEntity(id: string): Promise<AxiosResponse<Entity>> {
    return this.instance.get(`${Urls.admin.entities}/${id}`);
  }

  public async getEntities(query: FindEntityQuery): Promise<AxiosResponse<Entity[]>> {
    const url = queryString.stringifyUrl({ query, url: Urls.admin.entities });
    return this.instance.get(url);
  }

  public async createEntity(data: CreateEntityData): Promise<AxiosResponse<Entity>> {
    return this.instance.post(Urls.admin.entities, data);
  }

  public async updateEntity(data: { id: string; entity: UpdateEntityData }): Promise<AxiosResponse<Entity>> {
    const { id, entity } = data;
    const url = `${Urls.admin.entities}/${id}`;
    return this.instance.put(url, entity);
  }

  public async deleteEntity(id: string): Promise<AxiosResponse<Entity>> {
    return this.instance.delete(`${Urls.admin.entities}/${id}`);
  }

  public async getUtilitiesTypeDeclarations(
    query: FindUtilitiesTypeDeclarationsParameters,
  ): Promise<AxiosResponse<string>> {
    const stringifiedQuery = queryString.stringify({ utilities: query.utilities }, { arrayFormat: 'comma' });
    return this.instance.get(`${Urls.admin.utilitiesTypeDeclarations}?${stringifiedQuery}`);
  }

  public async testEntityMapping(query: TestEntityMappingQueryParameters): Promise<AxiosResponse<string>> {
    const url = queryString.stringifyUrl({ query, url: Urls.shared.debugToolbarEntityMappingTest });
    return this.instance.get(url);
  }

  public async createQuestionnaire(data: CreateQuestionnaireData): Promise<AxiosResponse<Questionnaire>> {
    return this.instance.post(Urls.admin.questionnaires, data);
  }

  public async fetchAllQuestionnaires(): Promise<AxiosResponse<Questionnaire[]>> {
    return this.instance.get(Urls.admin.questionnaires);
  }

  public async fetchAllLinesOfBusiness(): Promise<AxiosResponse<LineOfBusiness[]>> {
    return this.instance.get(Urls.admin.linesOfBusiness);
  }

  public async deleteQuestionnaire(questionnaireId: string): Promise<AxiosResponse<null>> {
    return this.instance.delete(`${Urls.admin.questionnaires}/${questionnaireId}`);
  }

  public async findUserGroupsForUser(userId: string): Promise<AxiosResponse<UserGroup[]>> {
    const url = queryString.stringifyUrl({
      url: Urls.admin.userGroups,
      query: { userId },
    });

    return this.instance.get(url);
  }

  public async findAllUserGroups(): Promise<AxiosResponse<UserGroup[]>> {
    return this.instance.get(Urls.admin.userGroups);
  }

  public async updateUserGroupsForUser(userId: string, groupNames: string[]): Promise<AxiosResponse<UserGroup[]>> {
    return this.instance.put(`${Urls.admin.userUserGroups}/${userId}`, { groupNames });
  }
}
